import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2,3,5,4,6,7,8];

export const dictionary = {
		"/": [~9],
		"/.internal": [~10,[2]],
		"/.internal/logo": [11,[2]],
		"/.internal/qr": [12,[2]],
		"/[lang]/(user)": [~27,[3,5]],
		"/[lang]/(user)/account": [~28,[3,5]],
		"/[lang]/(public)/confirm-email": [13,[3,4]],
		"/[lang]/(public)/confirm-email/next": [15,[3,4]],
		"/[lang]/(public)/confirm-email/[code]": [~14,[3,4]],
		"/[lang]/(user)/delete": [~29,[3,5]],
		"/[lang]/(public)/forgot-password": [16,[3,4]],
		"/[lang]/(public)/forgot-password/next": [18,[3,4]],
		"/[lang]/(public)/forgot-password/[code]": [17,[3,4]],
		"/[lang]/(public)/login": [~19,[3,4]],
		"/[lang]/(public)/login/guest": [20,[3,4]],
		"/[lang]/(public)/login/guest/landing": [~21,[3,4]],
		"/[lang]/(public)/logout": [~22,[3,4]],
		"/[lang]/(user)/organisations": [~30,[3,5]],
		"/[lang]/(user)/organisations/[id]": [~31,[3,5,6]],
		"/[lang]/(user)/organisations/[id]/adjustments": [~32,[3,5,6]],
		"/[lang]/(user)/organisations/[id]/passports": [~33,[3,5,6]],
		"/[lang]/(user)/organisations/[id]/requirements": [~34,[3,5,6]],
		"/[lang]/(user)/passports/create": [~38,[3,5]],
		"/[lang]/(user)/passports/[id]": [~35,[3,5,7]],
		"/[lang]/(user)/passports/[id]/edit": [~36,[3,5,7,8]],
		"/[lang]/(user)/passports/[id]/edit/requirements": [~37,[3,5,7,8]],
		"/[lang]/(public)/rate-limit": [~23,[3,4]],
		"/[lang]/(public)/settings": [24,[3,4]],
		"/[lang]/(user)/share/[code]": [~39,[3,5]],
		"/[lang]/(public)/signup": [~25,[3,4]],
		"/[lang]/(public)/update": [~26,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';